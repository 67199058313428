import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "./layout"
import { SectionContainer } from "./micros"
import styled from "styled-components"

const BlogSection = ({
  edges
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (<div>
      <SectionContainer style={{ padding: "0px", textAlign: "center" }}>
        <h1>Ostatnio na blogu:</h1>
        {/* <p>Tutaj możesz poczytać o wskazówkach dla osób z branży gamedev!</p>
        <p>Poznasz też jak tworzona jest nasza strona, od środka!</p> */}
        <div>{Posts}</div>
      </SectionContainer>
  </div>
  )
}

export default BlogSection


const PostLink = ({ post }) => (
  <PostLinkCotainer>
    <h3>
      <Link to={post.frontmatter.slug}>
        {post.frontmatter.title}
        {/* ,{" "}{post.frontmatter.date} */}
      </Link>
    </h3>
    <span>
      {post.frontmatter.preview}
    </span>
  </PostLinkCotainer>
)

const PostLinkCotainer = styled.div`

text-align:left;
margin:0px 15% ;

/* border:solid 1px;
border-radius:20px; */
padding:20px;

>h3{
  margin:5px 0px ;
}


`