import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import global from "../components/micros/global.css"
import { Hero, YouLove, Coop, Footer } from "../components/sections/"
import BlogSection from "../components/BlogSection"
import { graphql, Link } from "gatsby"


const IndexPage = (
  { data: {
    allMarkdownRemark: { edges },
  }, }
) => {

  return (
    <Layout>
      {/* {JSON.stringify(edges) || ""} */}
      <Seo title="Home" />
      <Hero />
      <BlogSection edges={edges} />
      <Coop />
      {/* <YouLove /> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] } limit:3) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            slug
            title
            preview
          }
        }
      }
    }
  }
`

export default IndexPage
